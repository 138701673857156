import React from 'react';
import Text from "@/components/common/Text";

const SingleColumnDescription = ({ desc, color }) => {
	return (
		<Text tag="div" className="typo-borna typo-borna-11 description" color={color} dangerouslySetInnerHTML={desc}/>
	)
};

export default SingleColumnDescription;
